import {request} from "./request";

export function getSearch() {
  return  request({
    params:{
      route:'product/category/getseachlist'
    }
  })
};export function getSearchList(search,page,limit) {
  return  request({
    params:{
      route:'product/search/getserch',
      search,page,limit
    },

  })
};