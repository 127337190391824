<template>
  <div>
    <img class="banner d-lg-block d-none" src="~assets/images/img/searchBanner.jpg"/>
    <div class="productDetail">
      <div class="rightTitle">
        <div class="rightTitleValue d-lg-block d-flex align-items-center">
          <p class="rightTitleValueC">产品查询</p>
          <p class="rightTitleValueE">Product Search</p>
        </div>
        <div class="rightTitleLinks d-none d-lg-flex">
          <p>当前位置：</p>
          <router-link class="link" to="/home" tag="p">首页</router-link>
          >
          <p class="linkLast">产品查询</p>
        </div>
      </div>
    </div>
    <div class="searchCoat">
      <div class="searchBox">
        <div class="search">
          <input class="searchInput" v-model="searchText" placeholder="请输入产品名称关键字，或CAS编码"/>
          <div class="searchIcoCoat" @click="searchBut">
            <img class="searchIco" src="~assets/images/icon/search.png"/>
          </div>
        </div>
        <div class="searchLinks">
          <p class="searchLinkText">例如：</p>
          <router-link  v-for="item in searchLinks" tag="p" :to="{
            path:'/searchcontent',
            query:{
              searchText:item
            }
          }" class="searchLinksItem">{{item}}
          </router-link>
        </div>
      </div>
      <div class="content">
        <!--标题-->
        <div class="contentList">
          <p class="serial">序号</p>
          <p class="Cas">CAS号</p>
          <p class="name">品名</p>
          <p class="contentItem">级别</p>
          <p class="contentItem">包装</p>
          <p class="contentItem">单位</p>
          <p class="contentItem">监控信息</p>
          <p class="contentItem">分类</p>
          <p class="contentItem">产品介绍</p>
        </div>
        <!--内容-->
        <div class="contentList list" v-for="(item,index) in tableContent">
          <p class="serial">{{item.order}}</p>
          <p class="Cas">{{item.CAS && item.cas!=''? item.CAS :'--'}}</p>
          <p class="name">{{item.name && item.name!=''? item.name :'--'}} </p>
          <p class="contentItem">{{item.packing && item.grade!=''? item.packing :'--'}} </p>
          <p class="contentItem">{{item.level && item.pack!=''? item.level :'--'}} </p>
          <p class="contentItem">{{item.company && item.unit!=''? item.company :'--'}} </p>
          <p class="contentItem">{{item.monitor && item.monitor!=''? item.monitor :'--'}} </p>
          <p class="contentItem">{{item.category && item.sort!=''? item.category :'--'}} </p>
          <p class="contentItem detail" @click="productDtl(item.product_id)">详细介绍</p>
        </div>
      </div>
      <main-pager ref="mainPage" :of-pages="ofPage" @pagerPage="pagerPage"/>
    </div>
  </div>
</template>
<script>
  import MainPager from "../../components/common/MainPager";
  import {getSearchList,getSearch} from 'network/search';
  export default {
    name: "SearchContent",
    components: {MainPager},
    methods: {
      pagerPage(num) {
        this.getSearchList(this.$route.query.searchText,num,this.limit);
      },
      // 获取搜索列表
      getSearchList(search,page,limit){
        getSearchList(search,page,limit).then(res=>{
          this.productTotal=res.data.product_total;
          this.tableContent=res.data.products;
        })
      },
      searchBut(){
        if (this.$route.query.searchText!=this.searchText){
          const query=JSON.parse(JSON.stringify(this.$route.query));
          query.searchText=this.searchText;
          this.$router.replace({
            query
          })
        }
        this.$refs.mainPage.page=1;
      },
      //  跳转详情页
      productDtl(product_id){
        if (this.$store.state.showProductDtl){
          this.$router.push('/productdetail/'+product_id)
        }
      },
    },
    computed:{
      ofPage(){
        if (this.productTotal){
          return Math.ceil(Number(this.productTotal)/this.limit);
        }
      },
    },
    created() {
      this.getSearchList(this.$route.query.searchText,1,this.limit);
      getSearch().then(res=>{
        // console.log(res.data);
        this.searchLinks=res.data.hot_search;
      });

    },
    watch:{
      '$route'(){
        this.getSearchList(this.$route.query.searchText,1,this.limit);
      }
    },
    data() {
      return {
        //一页条数
        limit:6,
        searchText:'',
        productTotal:null,
        searchLinks: [],
        tableContent: [],
      }
    }
  }
</script>

<style scoped>
  .content {
    overflow: hidden;
    overflow-x: scroll;
    width: 100%;
    margin: .8rem 0;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
  }

  .contentList {
    display: flex;
    font-size: .3rem;
    color: #8c8c8c;
    border: 1px solid #e6e6e6;
    min-width: 1186px;
    border-bottom: none;
  }

  .contentList:last-child {
    border-bottom: 1px solid #e6e6e6;;
  }

  .list:hover {
    background-color: #f7f9fc;
  }

  .list:hover .detail {
    cursor: pointer;
    color: var(--red);
  }

  .contentList .contentItem:last-child {
    border-right: none;
  }

  .contentList p {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::-webkit-scrollbar {
    /*display: none;*/
  }

  .serial {
    width: 100px;
  }

  .Cas {
    width: 205px;
    padding: 0 30px;
  }

  .name {
    width: 205px;
    padding: 0 30px;
  }

  .contentItem {
    flex: 1;
  }


  .contentList p {
    padding-top: .45rem !important;
    padding-bottom: .45rem !important;
    text-align: center;
    border-right: 1px solid #e6e6e6;
  }


  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .searchBox {
      margin: 0 10%;
    }
  }

  .searchCoat {
    padding: 0 var(--marginX);
    margin-bottom: 1.1rem;
  }


  .search {
    height: 1.6rem;
    display: flex;
    align-items: center;
    margin-bottom: .4rem;
  }

  .searchInput {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: .8rem;
    font-size: .35rem;
    flex: 1;
    border: 1px solid #bfbfbf;
    border-radius: 8px 0 0 8px;
    border-right: none;
  }

  .searchInput::placeholder {
    color: #bfbfbf;
  }

  .searchIcoCoat {
    border-radius: 0 8px 8px 0;
    height: 100%;
    background-color: var(--red);
    padding: .4rem .8rem;
    z-index: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .searchIco {
    height: 100%;
    width: auto;
  }

  .searchLinks {
    display: flex;
    align-items: center;
    padding-left: .8rem;
    font-size: .35rem;
    color: #bfbfbf;
  }

  .searchLinksItem {
    margin-left: .45rem;
    cursor: pointer;
  }

  .searchLinksItem:hover {
    text-shadow: 0 0 2px #bfbfbf;
  }

  .banner {
    width: 100%;
    height: auto;
  }

  .productDetail {
    padding: 0 var(--marginX);
  }

  .rightTitle {
    padding: 1.5rem 0 .3rem 0;
    margin-bottom: .55rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e2e2;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }

</style>